const COLORS = {
  primary: "#673de6",
  primaryDark: "#5025d1",
  primarySubtle: "#F3EFFD",
  bookedByClientWithSpecificEmployee: { backgroundColor: "#FFC6D0", color: "#F90038" },
  bookedByClientWithoutSpecificEmployee: { backgroundColor: "#FFEEDB", color: "#FF8000" },
  bookedByCompanyWithSpecificEmployee: { backgroundColor: "#E5F4FD", color: "#39B4F5" },
  bookedByCompanyWithoutSpecificEmployee: { backgroundColor: "#EBFAEA", color: "#5AD864" },
};

export default COLORS;
