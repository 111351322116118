// Logo Import
import logo from "@src/assets/images/logo/logo.svg";

// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: "Vuexy",
    appLogoImage: logo,
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "hidden", // static , sticky , floating, hidden. default: floating
      backgroundColor: "white", // BS color options [primary, success, etc]
    },
    footer: {
      type: "hidden", // static, sticky, hidden. default: static
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: "top-center", // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
    toastStyle: { background: "#333333", color: "white", fontSize: "16px", padding: 15 },
  },
};

export default themeConfig;
