// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** i18n
import "./configs/i18n";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";
import COLORS from "./utility/context/colors";
import "material-symbols";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

const muiTheme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  indicator: {
    backgroundColor: "red",
  },
  palette: {
    goldMui: {
      main: "#fcc46a",
    },
    grey: {
      primary: "#EBEBEB",
    },
    blue: {
      main: "#7367f0",
    },
    primaryMui: {
      // main: "#7367f0",
      main: "#3940E5",
    },
    primary: {
      main: COLORS.primary,
      // main: "#0076EF",
      light: "#0075EC",
    },
    black: {
      main: "#000",
    },
    danger: {
      main: "#ea5455",
    },
    whiteMui: {
      main: "#fff",
    },
    white: {
      main: "#fff",
    },
  },
});

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <ThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <ThemeContext>
              <LazyApp />
              <Toaster
                position={themeConfig.layout.toastPosition}
                toastOptions={{
                  className: "react-hot-toast",
                  style: themeConfig.layout.toastStyle,
                }}
              />
            </ThemeContext>
          </Suspense>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
